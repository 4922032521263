import React from "react"
import {Helmet} from "react-helmet";
import * as ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from 'react-bootstrap/Carousel';
const $ = window.$;

<Helmet>
    <script src="resource.js" type="text/javascript"> </script>
</Helmet>

class Uploadimg extends React.Component{

  fileObj = [];
  fileArray = [];

  constructor(props) {
      super(props)
      this.state = {
          file: [null]
      }
      this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
      this.uploadFiles = this.uploadFiles.bind(this)

  }
  handleOnClick = () => {
    $('#upFile').hide();
    $('#select-text').hide();
  };



  uploadMultipleFiles(e) {
      this.fileObj.push(e.target.files)
      for (let i = 0; i < this.fileObj[0].length; i++) {
          this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
      }
      this.setState({ file: this.fileArray })
  }

  uploadFiles(e) {
      e.preventDefault()
      console.log(this.state.file)
  }

render(){
return (
 <div className="uploadimg">
<div class="container">
    <div class="row" style={{marginTop:"3%",marginBottom:"3%"}}>

        <div class="col-md-3">
 <div class="card">
    <div class="card-body">
     <div class="row">
         <div class="col-md-4">
        <img src="assets/images/ba-08.png"  height= "68px" alt=""/>
        </div>
        <div class="col-md-8">
        <input type="checkbox" id="1" style={{float:"right"}}/>
        <p class="card-text">Background Remove</p>
         </div>
     </div>
    </div>
 </div>
        </div>

        <div class="col-md-3">
        <div class="card">
    <div class="card-body">
     <div class="row">
         <div class="col-md-4">
        <img src="assets/images/ba-08.png"  height= "68px" alt=""/>
        </div>
        <div class="col-md-8">
        <input type="checkbox" id="2" style={{float:"right"}}/>
        <p class="card-text">Image<br/> Resize</p>
         </div>
     </div>
    </div>
 </div>
        </div>


<div class="col-md-3">
<div class="card">
    <div class="card-body">
     <div class="row">
         <div class="col-md-4">
        <img src="assets/images/ba-08.png"  height= "68px" alt=""/>
        </div>
        <div class="col-md-8">
        <input type="checkbox" id="3" style={{float:"right"}}/>
        <p class="card-text">Image<br/> Recolor</p>
         </div>
     </div>
    </div>
</div>
   </div>


        <div class="col-md-3">
        <div class="card">
    <div class="card-body">
     <div class="row">
         <div class="col-md-4">
        <img src="assets/images/ba-08.png"  height= "68px" alt=""/>
        </div>
        <div class="col-md-8">
        <input type="checkbox" id="4" style={{float:"right"}}/>
        <p class="card-text">Photo<br/> Retouch</p>
         </div>
     </div>
    </div>
 </div>
        </div>
</div>








<div class="card" style={{width: "100%",borderRadius:"10px",backgroundColor:"#f3f5f7"}}>
    <div class="card-body">
    <div class="row">


<div class="col-md-8">
<div class="card">
  
<div style={{padding:"17% 8%",backgroundColor:"white"}}>
        <p id="select-text" style={{textAlign:"center",fontSize:"25px"}} class="card-text">Select a service and <br/>upload your images</p>
        <Carousel variant="dark">
                    {(this.fileArray || []).map(url => (
                      <Carousel.Item interval={60000}>
                        <img src={url} alt="..." height="400px" width="307px"/>
                        </Carousel.Item>
                    ))}                                  
        </Carousel>  
       <div id="upFile" class="text-center">
        <div class="upload-btn-wrapper">
                 <i class="fa fa-cloud-upload" style={{fontSize:"80px"}}></i> <br/>
                
            <button  style={{marginTop:"8%"}} class="uploadbtn">Upload a file</button>
            <input onClick={this.handleOnClick} type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple />
        </div>
        </div>

   </div>      
</div>
<br/>
    <div class="text-center">
        <button style={{border:"none",padding:"5px 38px",backgroundColor:"red",color:"white",borderRadius:"15px"}}>START</button>
    </div>

</div>

    

 <div class="col-md-4">
 <div style={{padding:"50% 25%"}}>
     <div class="text-center">
     <button id="dwnl">Download</button>
     <p style={{marginBottom:"-1%",marginTop:"1%",fontSize:"24px",fontWeight:"100"}}>or</p>
     <button id="dwnlall">Download All</button>
     </div>
     </div>
 </div>

</div>
</div>
 </div>



 {/* <div class="row">
                    <div class="col-md-3"></div>

                    <div class="col-md-6">
                    <Carousel variant="dark">
                    {(this.fileArray || []).map(url => (
                      <Carousel.Item>
                        <img src={url} alt="..." height="400px" width="307px"/>
                        </Carousel.Item>
                    ))}
                  </Carousel>      
                  </div>

                    <div class="col-md-3"></div>
  </div>   */}
  
     


 </div>
</div>
  );
}
}
export default Uploadimg;

ReactDOM.render(<Uploadimg />, document.getElementById("root"));
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Navigation, Footer, Home, About, Services, Contact,Login,Uploadimg,Payment } from "./components";
function App() {
  return (
    <Router >
    <div className="App">
    <ScrollToTop/>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/services" exact component={() => <Services />} />
          <Route path="/contact" exact component={() => <Contact />} />
          <Route path="/login" exact component={() => <Login />} />
          <Route path="/uploadimg" exact component={() => <Uploadimg />} />
          <Route path="/payment" exact component={() => <Payment />} />
        </Switch>
    </div>
    </Router>
  );
}

export default App;
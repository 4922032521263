import { render } from "@testing-library/react";
import React from "react";
import Card from "react-bootstrap/Card";
function Contact() {

  return (
    <div className="footer">
      <div class="container" style={{marginTop:"4%"}}>
      <div class="row">
        <div class="col-md-7">
      <table class="table">
        <thead class="table-dark"></thead>
        <tbody style={{border:"1px solid red"}}>
          
        <tr>
          <td><span id="header-col1">STARTER </span> <br/><span id="header-col2">250 Credit</span></td>
          <td><span id="body-col1">150$ </span><br/><span style={{fontSize:"12px"}}> *30 Days Validity</span></td>
          <td><button id="subsbtnbg">Subscribe</button></td>
        </tr>

          <tr>
          <td> <span id="header-col1">BASIC</span>  <br/><span id="header-col2">500 Credit</span></td>
          <td><span id="body-col1">280$ </span><br/><span style={{fontSize:"12px"}}> *30 Days Validity</span></td>
          <td><button id="subsbtn">Subscribe</button></td>
          </tr>
         
          <tr>
          <td><span id="header-col1">STANDARD</span>  <br/><span id="header-col2">750 Credit</span></td>
          <td><span id="body-col1">500$ </span><br/><span style={{fontSize:"12px"}}> *30 Days Validity</span></td>
          <td><button id="subsbtnbg">Subscribe</button></td>
          </tr>


          <tr>
          <td><span id="header-col1">ADVANCE</span>  <br/><span id="header-col2">1000 Credit</span></td>
          <td><span id="body-col1">750$ </span><br/><span style={{fontSize:"12px"}}> *30 Days Validity</span></td>
          <td><button id="subsbtnbg">Subscribe</button></td>
          </tr>



          <tr>
          <td><span id="header-col1">BUSINESS</span>  <br/><span id="header-col2">5000 Credit</span></td>
          <td><span id="body-col1">3000$ </span><br/><span style={{fontSize:"12px"}}> *30 Days Validity</span></td>
          <td><button id="subsbtnbg">Subscribe</button></td>
          </tr>


        </tbody>
      </table>
  
      </div>


      <div class="col-md-1"></div>
      <div class="col-md-4">
      <Card style={{ width: '18rem', border:"1px solid red"}}>
         
          <Card.Body>
            <Card.Title><span id="header-col1">BASIC</span><sub style={{backgroundColor:"#2ccdff",color:"black",fontSize:"10px",fontWeight:"600",padding:"3px 13px"}}>*MOST CONVINIENT</sub>  <br/><span id="header-col2">500 Credit</span> </Card.Title>
            <hr style={{backgroundColor:"red"}}/>
            <Card.Text>
              <ul id="list"><span style={{fontWeight:"600",color:"#2ccdff"}}> Plan Benefits </span>   
                <li>Benefits Number 1</li>
                <li>Benefits Number 2</li>
                <li>Benefits Number 3</li>
                <li>Benefits Number 4</li>
              </ul>

              <ul id="list"><span style={{fontWeight:"600",color:"#2ccdff"}}> Plan Features </span>   
                <li>Features Number 1</li>
                <li>Features Number 2</li>
                <li>Features Number 3</li>
                <li>Features Number 4</li>
              </ul>
              
              <hr style={{backgroundColor:"red"}}/>
            </Card.Text>
            <span id="body-col1">280$ </span><br/><span style={{fontSize:"12px"}}> *30 Days Validity</span>
          </Card.Body>
        </Card>

      </div>
      </div>

      
      <Card style={{ width: '57.5%', border:"1px solid red"}}>
          <Card.Body>
            <Card.Text>
              <div class="row">
                <div class="col-md-4">
                <p style={{fontSize:"24px",fontWeight:"600"}}>Pay-As-You-Go</p>
                <span id="header-col2">*1 Credit = 0.2$</span>
                </div>
                <div class="col-md-4">
                  <table>
                    <tr>
                      <td><input style={{ width: "5rem"}} type="number"/></td>
                      <td>280$</td>
                    </tr>
                  </table>
                  <span style={{fontSize:"12px",paddingLeft:"20%"}}> *30 Days Validity</span>
                </div>

                <div class="col-md-4">
                <button id="subsbtnbg">Purchase</button>
                </div>
              </div>
             
            </Card.Text>
          </Card.Body>
        </Card>
<br/>


      </div>
    </div>
  );
 
}

export default Contact;

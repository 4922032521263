import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav, Container } from 'react-bootstrap';

function Navigation(props) {
  return (
    
    <Navbar collapseOnSelect sticky='top' expand='sm' bg='dark' variant='dark' height="30px">
    <Container>
    <Link class="navbar-brand" to="/">
      <img src="../../assets/images/ailogo.png" width="100%" height="100%" alt="" />
    </Link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <div class="mx-auto"></div>
        <ul class="navbar-nav">
        <li class="nav-item active">
                <Link class="nav-link" to="/">
                  HOME
                  <span class="sr-only">(current)</span>
                </Link>
              </li>


              <li class="nav-item">
              <Link class="nav-link" to="/about">
                  How to Use
                  
                </Link>
              </li>

             

              <li class="nav-item">
              <Link class="nav-link" to="/services">
                  Tools & API
                </Link>
              </li>


              <li class="nav-item">
              <Link class="nav-link" to="/uploadimg">
                  Upload Image
                </Link>
              </li>

              <li class="nav-item">
              <Link class="nav-link" to="/contact">
                  Pricing
                </Link>
              </li>

              <li class="nav-item">
              <Link class="nav-link" to="/payment">
                  Payment
                </Link>
              </li>



              <li class="nav-item">
                <Link class="nav-link" to="/login"><button id="button9">
                  SIGN UP</button>
                </Link>
              </li>


              

              {/* <li class="nav-item">
              <div class="dropdown">
                <img src="assets/images/signup-09.png"/>
                   <div class="dropdown-content">
                      <a href="#">Link 1</a>
                      <a href="#">Link 2</a>
                      <a href="#">Link 3</a>
               </div>
             </div>

           </li> */}
             

        </ul>
      </div>
    </Container>
  </Navbar>
    
  );
}

export default withRouter(Navigation);
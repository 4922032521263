import React from "react";
import Card from "react-bootstrap/Card";
function Payment() {

  return (
    <div className="footer">
      <div class="container" style={{marginTop:"4%"}}>

      <div class="row">
        <div class="col-md-7">

        <Card style={{ border:"1px solid red"}}> 
         <Card.Body>  
          <div class="row">
             <div class="col-md-6">
            
            <span style={{fontWeight:"600"}}> Credit Card</span>   
             <p style={{color:"silver",fontSize:"14px"}}>Safe money transfer using your bank account. We support
mastercard, visa, maestro & skrill.</p>
          
             </div>

          <div class="col-md-6">
            <div class="card-row">
              <span class="visa"></span>
              <span class="mastercard"></span>
              <span class="amex"></span>
              <span class="discover"></span>
             </div>
             </div>
           </div>     

            
           
           <input type="number" placeholder="   2546 2699 7866 3677" id="fname" name="fname" style={{width:"100%",marginBottom:"5%"}}/>

           <div class="row">
             <div class="col-md-5">
             <label>Name On Card</label>             
             <input type="text" id="fname" name="fname" style={{width:"100%"}}/>

             </div>
             <div class="col-md-3">
             <label>Expiry Date</label>   
             <input type="text" id="fname" name="fname" style={{width:"100%"}}/>
               
             </div>
             <div class="col-md-4">
             <label>CVV Code</label>   
             <input type="text" id="fname" name="fname" style={{width:"100%"}}/>
               
             </div>

             
           </div>

           <button style={{width:"100%",marginTop:"5%",border:"none",backgroundColor:"red",color:"white",padding:"4px",fontWeight:"600"}}>CONFIRM</button>
          


         
         </Card.Body>
       </Card>

      


       <Card style={{ border:"1px solid red",marginTop:"5%"}}>
         
         <Card.Body>       
         <div class="row">
             <div class="col-md-8">
            
            <span style={{fontWeight:"600"}}> Paypal</span>   
             <p style={{color:"silver",fontSize:"14px"}}>Pay your order using the most known and secure platform for online
money transfer. You will be redirected to paypal to finish
your purchase.</p>
          
             </div>

          <div class="col-md-4">
            <div class="card-row">
              <span style={{float:"right"}} class="discover"></span>
             </div>
             </div>
           </div>     
         
         </Card.Body>
       </Card>



        </div>

        <div class="col-md-5">
        <Card style={{ width: '18rem', border:"1px solid red"}}>
         
         <Card.Body>
           <Card.Title><span id="header-col1">BASIC</span><sub style={{backgroundColor:"#2ccdff",color:"black",fontSize:"10px",fontWeight:"600",padding:"3px 13px"}}>*MOST CONVINIENT</sub>  <br/><span id="header-col2">500 Credit</span> </Card.Title>
           <hr style={{backgroundColor:"red"}}/>
           <Card.Text>
             <ul id="list"><span style={{fontWeight:"600",color:"#2ccdff"}}> Plan Benefits </span>   
               <li>Benefits Number 1</li>
               <li>Benefits Number 2</li>
               <li>Benefits Number 3</li>
               <li>Benefits Number 4</li>
             </ul>

             <ul id="list"><span style={{fontWeight:"600",color:"#2ccdff"}}> Plan Features </span>   
               <li>Features Number 1</li>
               <li>Features Number 2</li>
               <li>Features Number 3</li>
               <li>Features Number 4</li>
             </ul>
             
             <hr style={{backgroundColor:"red"}}/>
           </Card.Text>
           <span id="body-col1">280$ </span><br/><span style={{fontSize:"12px"}}> *30 Days Validity</span>
         </Card.Body>
       </Card>
        </div>

    </div>


      </div>
    </div>
  );
 
}

export default Payment;
